@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Source Sans Pro';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mainContent {
  background-color: #777777; 
  background-size: 100% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.singleArtworkBackground {
  background-size: 100% 100%;
  background-color: #FFF; 
}

.traits {
  font-family: var(--bs-font-monospace);
}

a {
  color: #8888BB !important;  
  text-decoration: none !important;
}

a.btn {
  color: #FFFFFF !important;  
}

.buttonLogo {
  height: 20px;
  padding-right: 6px;
  margin-top: -2px;
}

div.dropdown {
  display: inline-block;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } 
}

.home {
  opacity: 0.9;
}

.samples {
  background-color: #EEEEEE;
  border-radius: 0.5rem;
  margin: 16px auto;
  width: 80%;
  max-width: 600px;
}

.sample {
  width: 10%;
  margin: 16px;
  min-width: 120px;
  /* border: 1px solid #CCC; */
}

.actions {
  text-align: center;
  margin: auto;
}

.socialLinks {
  display: inline-block;
  text-align: center;
  background-color: #CCC;
  border-radius: 0.5rem;
  margin: 24px auto 8px;
}

.socialLogo {
  height: 40px;
  margin: 8px;
}

.mid20th  {
  text-align: center;
  margin: 20px;

}


.featureList {
  text-align: left;
  margin: 0px;
}

.homeTitle {
  margin: 20px;
}

.homeAbout {
  display: inline-block;
  margin: auto;
}
 
h5 {
  font=size: 32px;
}

.deepContent {
  font-size: 18px;
}


.deepContent p {
  margin: 0 16px;
}

.card {
  background-color: #777777;
  margin: 16px;
  border: none !important;
}

.tokenCard {
  background-color: #777777;
}

.card-header {
  color: #eee !important;
  background-color: #CCC !important;
}

.card-body {
  background-color:#DDD;

}

.card-header {
  text-align: left;
  color: #AAAAAA;
}

.card-body {
  font-size: 18px;
} 

.btn {
  margin: 8px;
}

/* #mint {
  background: #d3d3d3EE !important;
} */

.mintRow {
  margin: 16px 0 0;
  display: flex;
  text-align: center;
  /* clear: both; */
  flex-wrap: wrap;
}

.mintInputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: right;
  margin: 0 auto;
  padding: 16px;
  width: 30vw; 
  max-width: 500px;
  max-height: 500px;
}

.mintInput {
  font-size: 1rem;
}

/* for tint pickers... making similar to others */
.mintPicker div {
  border-radius: 13px !important;
}

.card-title {
  font-size: 32px;
  font-weight: bold;
  margin: 8px;

}

div#accountDetails {
  text-align: left;
}

.cardArtwork {
  width: 250px; 
  height: 250px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.galleryImage {
  width: 250px; 
  height: 250px;
}

.cardTraits {
  width: 250px; 
}

.singleArtwork {
  background: #FFF;
  border: 10px solid #EEE; 
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.singleArtworkDetail {
  margin: 16px;
}

.singleArtworkTraitsAccordion {
  margin: 16px 0 8px;
}


.dynaStripesArtwork div.spinner {
  color: green;

}

.token {
  margin: 16px;
  opacity: 1;
}

p.success {
  margin-top: 16px;
  font-size: 18px;
  max-width: 400px;
}

div.mintInput input {
  margin: 8px;
}

#mintSpinner {
  height: 16px;
  width: 16px;
}

.textTraits {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.mintEther {
  margin-top: -4px;
  height: 16px;
  width: 16px;
}

.mintMatic {
  margin-top: -4px;
  height: 32px;
  width: 32px;
}

.navDropdownIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.betaMessage {
  color: red;
  font-style: italic;
  padding: 0.5rem 0;
}

.dyna {
  font-family: 'Rajdhani';
}

.volstrate {
  font-family: 'Rajdhani';
  font-weight: bold;
}
/* 
.row {
  display: flex;
  flex-wrap:wrap;
  } */

.tokenList {
  /* padding: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: center;
}

.tokenCard {
  /* width: 40vw; */
  /* margin: 16px 0; */
}

.deepContent {
  text-align: left;
  margin: 0px 16px;
}

h4.dyna {
  margin-top: 16px;
}

.deepContent p {
  margin: 8px 8px;
}

.content {
  /* background-color: #d3d3d3EE; */
  background-color:#AAAAAADD;
  box-shadow: 0 0 10px 5px rgb(100 100 100 / 0.2 );

  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.5rem;
  margin: 50px 0;
  padding: 16px;
  max-width: min(1200px, 70vw);
  min-width: 320px;
}

.previewContainer {
  /* background-color: #FFFFFF;
  padding: 10px;
  margin: 0 auto; */
  opacity: 1;
  margin: auto;
}

.previewArtwork {
  opacity: 1;
  margin: 16px;
  background-color:#FFF;
  width: 30vw; 
  max-width: 500px;
  border: 10px solid #EEE; 
}

@media screen and (max-width: 700px) {
  .samples {
    width: initial;
  }
  
  .previewArtwork {
    width: 70%; 
    height: initial;
    margin: 8px;
    border-width: 0px;
    margin-bottom: 0px;
  }
  .cardArtwork {
    width: 200px; 
    height: 200px;
  }
  
  .galleryImage {
    width: 200px; 
    height: 200px;
  }
  
  .cardTraits {
    width: 200px;
  }

  .mintInputs {
    float: right;
    margin: 0 auto;
    width: 90%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }  
  .mintInput {
    margin: 0;
    font-size: 0.8rem;
  }  

  .mintRow {
    margin: 0;
  } 

  .content {
    margin: 32px 16px;
    padding: 8px;
    max-width: initial;
  }  

  .singleArtwork {
    width: 250px;
  }
  
}

.polygonscan {
  height: 1.2rem;
}

.preview {
  float: left;
}

 /* ---- from static  XAMPP website */

 body {
  font-family: 'Source Sans Pro';
	position: relative;
	width: 100%;
	height: 100%;
  background-size: 100% 100%;
  background-color: white;
}

/* div#placeholderContent {
  /* position: absolute; */
  /* top: 10%; */
  /* -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
  /* padding: 16px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  left: 25%;
  border-radius: 10px;  
  background-color:#AAAAAADD;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 0.18);
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
/* } */

.placeholderTitle {
	color: white;
	text-align: center;
	font-family: 'Rajdhani';
	font-size: 50px;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

div#placeholderContent {
	color: white;
	text-align: center;
	font-size: 32px;
  margin: 0px 20px;
}

div.placeholderLinks {
	color: white;
	text-align: center;
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 50px;
}

div.placeholderAttribution {
  margin-top: 10px;
  margin-bottom: 10px;
}

.volstrate {
  font-family: 'Rajdhani';
}

.externalLink, .textLink { 
  font-weight: 700; 
  color: #6363e6!important; 
  text-decoration: none !important;
  cursor: pointer;
}


